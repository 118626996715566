import React, { useEffect } from 'react'
import { Grid2 } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import MAutocomplete, { AutoCompleteOption } from '../material/MAutocomplete'
import MDateTimePicker from '../material/MDateTimePicker'
import MCheckbox from '../material/MCheckbox'
import MInput from '../material/MInput'
import { Order } from '../../types/order'
import { ralColorsPalette } from '../../utils/ralColorsPalette'
import { useParams } from 'react-router-dom'
import {
  finishesOptions,
  glossLevelOptions,
  paintTypeOptions,
  quantityTypes,
} from '../../utils/selectorsOptions'
import useOrdersStore from '../../store/useOrdersStore'
import useDocumentsStore from '../../store/useDocumentsStore'
import SignaturePad from '../SignaturePad'

interface OrderDetailsFormProps {
  methods: UseFormReturn<Order>
}

const checkboxOptions = [
  { value: 'yes', label: 'Tak' },
  { value: 'no', label: 'Nie' },
]

export default function OrderDetailsForm(props: OrderDetailsFormProps) {
  const orderId = useParams().orderId

  const { getCiNumber } = useOrdersStore()
  const { activeGrnNumber } = useDocumentsStore()

  const { setValue, control, watch } = props.methods

  const sandblasting = watch('sandblasting')

  const onlySandblasting = sandblasting === 'only'

  const ralColorsOptions: AutoCompleteOption[] = Object.keys(
    ralColorsPalette,
  ).map((value) => ({ value, label: value }))

  useEffect(() => {
    if (!orderId && !activeGrnNumber) {
      getCiNumber().then((batchNumber) => {
        setValue('ciNumber', batchNumber)
      })
    } else if (!orderId && activeGrnNumber) {
      setValue('ciNumber', activeGrnNumber)
    }
  }, [])

  return (
    <Grid2 container direction="row" gap={4}>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <Controller
          name="ralColor"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              disabled={onlySandblasting}
              fullWidth
              value={value}
              onChange={(value: string) =>
                setValue('ralColor', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={ralColorsOptions}
              label="Kolor RAL"
            />
          )}
        />
        <Controller
          name="glossLevel"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              disabled={onlySandblasting}
              value={value}
              label="Stopień połysku"
              onChange={(value: string) =>
                setValue('glossLevel', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={glossLevelOptions}
              fullWidth
            />
          )}
        />
        <Controller
          name="finishing"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              disabled={onlySandblasting}
              value={value}
              label="Wykończenie"
              onChange={(value: string) =>
                setValue('finishing', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={finishesOptions}
              fullWidth
            />
          )}
        />
        <Controller
          name="paintType"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              disabled={onlySandblasting}
              value={value}
              label="Rodzaj farby"
              onChange={(value: string) =>
                setValue('paintType', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={paintTypeOptions}
              fullWidth
            />
          )}
        />
        <Grid2 container direction="row" gap={2}>
          <Controller
            name="quantity"
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <MInput
                style={{ width: 'calc(25% - 12px)' }}
                required
                variant="outlined"
                label="Ilość"
                type="number"
                value={value}
                onChange={onChange}
                helperText={errors.quantity?.message}
              />
            )}
          />
          <Controller
            name="quantityType"
            control={control}
            render={({ field: { value } }) => (
              <MAutocomplete
                style={{ width: '100px' }}
                label="Typ"
                value={value}
                onChange={(value: string) =>
                  setValue('quantityType', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                options={quantityTypes}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            render={({ field: { value }, formState: { errors } }) => (
              <MInput
                required
                style={{ width: 'calc(75% - 120px)' }}
                variant="outlined"
                label="Nazwa towaru"
                type="text"
                value={value}
                onChange={(event) =>
                  setValue('product', event.target.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                helperText={errors.product?.message}
                inputProps={{
                  maxLength: 40,
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 container direction="row">
          <Grid2 style={{ flex: 1 }}>
            <Controller
              name="base"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MCheckbox
                  disabled={onlySandblasting}
                  onChange={onChange}
                  id="base"
                  label="Podkład"
                  defaultValue={value}
                  options={checkboxOptions}
                />
              )}
            />
          </Grid2>
          <Grid2 style={{ flex: 1 }}>
            <Controller
              name="sandblasting"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MCheckbox
                  onChange={onChange}
                  id="sandblasting"
                  label="Piaskowanie"
                  defaultValue={value}
                  options={[
                    ...checkboxOptions,
                    { value: 'only', label: 'Tylko piaskowanie' },
                  ]}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        direction="column"
        container
        sx={{ flex: 1 }}
        alignItems="flex-end"
      >
        <Controller
          name="date"
          control={control}
          render={({ field: { value, onChange } }) => (
            <MDateTimePicker
              fullWidth
              value={value as number}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="ciNumber"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="PZ"
              type="text"
              value={value}
              onChange={onChange}
              helperText={errors.ciNumber?.message}
            />
          )}
        />
        {!orderId && (
          <SignaturePad
            title="Podpis zlecającego"
            onEnd={(signature) =>
              setValue('signature', signature, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            clearSignature={(signature) =>
              setValue('signature', signature, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
        )}
      </Grid2>
    </Grid2>
  )
}
