import React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

interface MCheckboxProps {
  id: string
  defaultValue: string
  options: { value: string; label: string }[]
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  disabled?: boolean
}

export default function MCheckbox(props: MCheckboxProps) {
  return (
    <FormControl>
      <FormLabel id={props.id}>{props.label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={props.id}
        value={props.defaultValue}
        onChange={props.onChange}
      >
        {props.options.map((opt) => (
          <FormControlLabel
            disabled={props.disabled}
            key={opt.value}
            value={opt.value}
            control={<Radio />}
            label={opt.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
