import { Autocomplete, styled, TextField } from '@mui/material'
import React, { CSSProperties, SyntheticEvent } from 'react'
import { get } from 'lodash'

const CustomAutoComplete = styled(Autocomplete)(() => ({
  width: '300%',
  height: '90px',
  '& div': {
    backgroundColor: '#ffffff',
  },
}))

export interface AutoCompleteOption {
  label: string
  value: string
}

interface MAutocompleteProps {
  label: string
  options: AutoCompleteOption[]
  fullWidth?: boolean
  value: string
  onChange: (value: string) => void
  style?: CSSProperties
  disabled?: boolean
}

export default function MAutocomplete(props: MAutocompleteProps) {
  return (
    <CustomAutoComplete
      style={
        props.style ? props.style : props.fullWidth ? { width: '100%' } : {}
      }
      disabled={props.disabled}
      options={props.options}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      value={props.options.find((opt) => opt.value === props.value) || ''}
      onChange={(_: SyntheticEvent<Element, Event>, newValue: unknown) => {
        props.onChange((newValue as AutoCompleteOption).value)
      }}
      getOptionLabel={(option) => get(option, 'label', '')}
      getOptionKey={(option) => get(option, 'value', '')}
      disableClearable
    />
  )
}
